
<template>
  <div class="about">
    <div class="banner">
      <div class="text">
        <p>About us</p>
        <p>关于我们</p>
        <p>迪尔出行</p>
      </div>
    </div>
    <div class="introduction-wrapper">
      <div class="introduction">
        <div class="title-wrapper">
          <UnderlineTitle>公司介绍</UnderlineTitle>
        </div>
        <div class="content">
          <p>迪尔出行是青岛路远信息技术有限公司打造的智慧出行生态平台，依托移动互联网技术构建的面向全国商旅出行人士的网络预约出租汽车经营服务方。</p>
          <p>迪尔将以全新的姿态，全方位的布局，独特的运营思路，稳固的技术支撑，逐步实现品牌的行业基准，并引领行业更向前一步发展。</p>
        </div>
      </div>
    </div>
    <div class="license">
      <div class="title-wrapper">
        <UnderlineTitle>证书展示</UnderlineTitle>
      </div>
      <div class="licenseList">、
        <img src="../assets/about/license.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'About'
}
</script>
<style lang="scss" scoped>
.about {
  .banner {
    background-image: url('../assets/about/banner.png');
    background-position: center;
    background-size: 100% 100%;
    height: 36.98vw;
    min-width: 960px;
    min-height: 400px;
    position: relative;
    > .text {
      position: absolute;
      left: 53.64%;
      top: 33.8%;
      > p {
        color: #fff;
        text-align: right;
      }
      > p:first-child {
        font-size: 4.17vw;
        // font-size: 80px;
        color: #94a9be;
      }
      > p:nth-child(2) {
        font-size: 2.19vw;
        // font-size: 42px;
        font-weight: 200;
      }
      > p:last-child {
        font-size: 3.02vw;
        // font-size: 58px;
      }
    }
  }
  > .introduction-wrapper {
    background-color: #f7f7f7;
    text-align: center;
    > .introduction {
      background-color: #fff;
      box-shadow: 0 0 4px 1px rgba(0, 0, 0,0.1);
      width: 67.7vw;
      margin: auto;
      border-radius: 24px;
      transform: translateY(-5.73vw);
      min-width: 947px;
      > .title-wrapper {
        background-image: url('../assets/about/company.png');
        background-position: center;
        background-repeat: no-repeat;
        padding: 100px 0 90px 0;
      }
      > .content {
        text-align: left;
        padding: 0 6.98vw 6.51vw 5.94vw;
        > p {
          text-indent: 2em;
          font-size: 26px;
          font-weight: 200;
        }
      }
    }
  }
  > .license {
    padding-bottom: 80px;
    > .title-wrapper {
      background-image: url('../assets/about/honor.png');
      background-position: center;
      background-repeat: no-repeat;
      padding: 100px 0 90px 0;
    }
    > .licenseList {
      text-align: center;
      > img {
        width: 66.67vw;
      }
    }
  }
}
</style>